* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

html {
   height: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

a {
   text-decoration: none;
}

#root {
   height: 100%;
}
